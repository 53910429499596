
const config = require("./configuration.json");
if (config.useLocationOrigin) {
    //origin (https://sentient.ws) + pathname ('/s4pi/stratesys/test/')
    //config.server = window.location.origin + "/" /*+ window.location.pathname*/;

    let foundNewAdmin = (window.location.origin + window.location.pathname).includes('/newAdmin');

    if (foundNewAdmin) {
        config.server = (window.location.origin + window.location.pathname).split('/newAdmin')[0] + "/";
    }
    else {
        config.server = window.location.origin + window.location.pathname;
    }
}
var qs = require('qs');

const demoAPI = {

};

const realAPI = {
    user: {
        login: function (user, password) {
            return {
                method: "POST",
                url: config.server + 'rest/login/loginPrimary',
                params: {
                    user: user,
                    password: password
                }
            }
        },
        logout: function () {
            return {
                method: "GET",
                url: config.server + 'logout'
            }
        },
        getRole: function () {
            return {
                method: "GET",
                url: config.server + 'rest/user/getRole'
            }
        },
        getUser: function () {
            return {
                method: "GET",
                url: config.server + 'rest/user/'
            }
        },
        updateUserConfig: function (userId, userCode, password) {
            return {
                method: "POST",
                url: config.server + 'rest/user/userConfig',
                params: {
                    id: userId,
                    name: userCode,
                    pass: password
                }
            }
        },
        sendPasswordRecoveryMail: function (email) {
            return {
                method: "POST",
                url: config.server + 'rest/user/sendPasswordRecoveryMail',
                params: {
                    mail: email
                }
            }
        },
        changePasswordForToken: function (token, newPassword) {
            return {
                method: "POST",
                url: config.server + 'rest/user/changePasswordForToken',
                params: {
                    token: token,
                    newPassword: newPassword
                }
            }
        }
    },
    dashboard: {
        /**
         * Obtains all Factors and its subfactors
         * @returns 
         */
        getFactors: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getFactors'
            }
        },
        getEvolution: function (initialDate, finalDate, demographicFilters) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getEvolution',
                params: {
                    initialDate: initialDate,
                    finalDate: finalDate,
                    filters: parseFilters(demographicFilters)
                }
            }
        },
        getDemographics: function (includeCommon = true) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getDemographics',
                params: {
                    includeCommon: includeCommon
                }
            }
        },
        getCommonDemographics: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getCommonDemographics'
            }
        },
        /**
         * Include surveyed fields as demograhpic format, used to filter
         * @param {*} includeCommon 
         * @returns 
         */
        getAllDemographics: function (includeCommon = true) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getAllDemographics',
                params: {
                    includeCommon: includeCommon
                }
            }
        },
        /**
         * Obtain the possible values for an specific demographic by Id, used to filter
         * @param {*} dgId 
         * @returns 
         */
        getDemographicValues: function (dgId, name) {

            if (dgId != 0) {
                return {
                    method: 'GET',
                    url: config.server + 'rest/dashboard/getDemographicValues',
                    params: {
                        demographic: dgId
                    }
                }
            }
            else {
                return {
                    method: 'GET',
                    url: config.server + 'rest/dashboard/getDemographicValues',
                    params: {
                        demographic: name
                    }
                }
            }
        },
        getComparasionGroups: function (initialDateA,
            initialDateB,
            finalDateA,
            finalDateB,
            filtersA,
            filtersB) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getClimateComparison',
                params: {
                    initialDateA: initialDateA,
                    initialDateB: initialDateB,
                    finalDateA: finalDateA,
                    finalDateB: finalDateB,
                    filtersA: parseFilters(filtersA),
                    filtersB: parseFilters(filtersB)
                }
            }
        },
        getEvolutiveFactor: function (initialDate, finalDate, filters, type) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getEvolutive',
                params: {
                    initialDate: initialDate,
                    finalDate: finalDate,
                    filters: parseFilters(filters),
                    type: type
                }
            }
        },
        getWeightedClimate: function (initialDate, finalDate, filters) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getWeightedClimate',
                params: {
                    initialDate: initialDate,
                    finalDate: finalDate,
                    filters: parseFilters(filters)
                }
            }
        },
        getGlobalClimate: function (initialDate, finalDate, filters) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getGlobalClimate',
                params: {
                    initialDate: initialDate,
                    finalDate: finalDate,
                    filters: parseFilters(filters)
                }
            }
        },
        getNPS: function (initialDate, finalDate, filters) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getNPS',
                params: {
                    initialDate: initialDate,
                    finalDate: finalDate,
                    filters: parseFilters(filters)
                }
            }
        },
        getMoodItems: function (initialDate, finalDate, filters) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getAdditionalItems',
                params: {
                    type: "happ",
                    initialDate: initialDate,
                    finalDate: finalDate,
                    filters: parseFilters(filters!= null ? filters : {})
                }
            }
        },
        getKillerItems: function (initialDate, finalDate, filters) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getAdditionalItems',
                params: {
                    type: "killer",
                    initialDate,
                    finalDate,
                    filters: parseFilters(filters!= null ? filters : {})
                }
            }
        },
        getParticipation: function (initialDate, finalDate, filters) {
            return {
                method: 'GET',
                url: config.server + 'rest/dashboard/getFilteredParticipation',
                params: {
                    initialDate: initialDate,
                    finalDate: finalDate,
                    filters: parseFilters(filters!= null ? filters : {})
                }
            }
        }
    },
    ratios: {
        storeRatioFile: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/ratios/storeRatioFile',
                data: data
            }
        },
        getAllRatios: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/ratios/getAllRatios'
            }
        },
        deleteRatioFile: function (fileName) {
            return {
                method: 'DELETE',
                url: config.server + 'rest/ratios/deleteRatioFile',
                params: {
                    fileName: fileName
                }
            }
        },
    },
    events: {
        uploadEvents: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/events/addByCSV',
                data: data
            }
        },
        getEvents: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/events/getEvents'
            }
        },
        deleteEvent: function (id) {
            return {
                method: 'DELETE',
                url: config.server + 'rest/events/deleteEvent',
                params: {
                    id: id
                }
            }
        },
    },
    surveys: {
        getSurveyLiterals: function (language = 'es') {
            return {
                method: 'GET',
                //url: config.server + 'user/getProjects'
                url: config.server + 'survey/getSurveyLiterals?language=' + language
            }
        },

    },
    weeklyPulses: {
        getWeeklyPulses: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/pulseConfig/getPulseConfig'
                //url: config.server + 'api/weekly-pulses/getWeeklyPulses.json'
            }
        },
        updateWeeklyPulses: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/pulseConfig/updatePulseConfigs',
                data: data
            }
        },
        updateWeeklyPulse: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/pulseConfig/updatePulseConfig',
                data: data
            }
        },
        /**
         * Debug purpose
         * @param {*} data pulseConfig
         * @returns 
         */
        sendPulseImmediately: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/pulseConfig/sendPulse',
                data: data
            }
        }
    },
    triggers: {
        getAvailableUsers: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/surveyed/getAllClients'
            }
        },
        /**
         * Require FormData
         * e.g survey=fin-UAT&eventName=test&surveyeds=16,6
         * @param {*} data 
         * @returns 
         */
        createEventSurvey: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/survey/createEventSurvey',
                data: data,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            }
        }
    },
    riskAdmin: {
        /**
         * Obtain all risks (of the current selected project)
         * @returns 
         */
        getProjectRisks: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/risk/getProjectRisks'
            }
        },
        /**
         * {
         *  language: "es",
         *  code: "risk01",
         *  name: "",
         *  description: "",
         *  itemText: ""
         * }
         * @param {*} data 
         * @returns 
         */
        updateRisk: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/risk/updateRisk',
                data: data
            }
        },
        updateRiskState: function (data) {
            return {
                method: 'PUT',
                url: config.server + 'rest/risk/enableRisk',
                data: data
            }
        },
        /**
         * It creates a new risk
         * @param {*} data eg {"es":{"name":"Test Risk 01","description":"Desc of Test Risk 01","itemText":"Text of question of Risk 01"}}
         * @returns 
         */
        createRisk: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/risk/createRisk',
                data: data
            }
        },
        deleteRisk: function (data) {
            return {
                method: 'DELETE',
                url: config.server + 'rest/risk',
                data: data
            }
        }
    },
    pulseDetail: {

        getSurveyTypes: function () {
            return {
                method: 'GET',
                url: config.server + 'api/pulse-detail/getSurveyTypes.json'
            }
        },
        getSurveyLiterals: function (language) {
            return {
                method: 'GET',
                url: config.server + 'rest/survey/getSurveyLiterals',
                params: {
                    language: language
                }
                //url: config.server + 'api/pulse-detail/getSurveyLiterals.json'
            }
        },
        /**
         * Todo remove params
         * @param {*} name 
         * @param {*} language 
         * @returns 
         */
        getSurvey: function (name, language) {
            return {
                method: 'GET',
                //url: config.server + 'api/pulse-detail/getSurvey.json',
                url: config.server + 'rest/survey/getSurvey',
                params: {
                    name: "",
                    language: ""
                }
            }
        }
    },
    surveyedAdmin: {
        getAllSurveyed: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/surveyed/getAllSurveyed'
            }
        },
        /**
         * url encoded
         * email=paco@stratesys-ts.com&country=ES&bornDate= &project=undefined&language=es&jobPosition=123&scope=client&projectTeam=true&department=123&companyFunction=123&age=123&studyLvl=123
         * @param {*} surveyed 
         * @returns 
         */
        createSurveyed: function (surveyed) {
            return {
                method: 'POST',
                url: config.server + 'rest/surveyed/createSurveyed',
                data: surveyed,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            }
        },
        /**
         * url encoded
         * idSurveyed=24&email=paco@stratesys-ts.com&country=ES&jobPosition=test&language=es&scope=stratesys&department=test&companyFunction=test&age=19&studyLvl=test
         * @param {*} surveyed 
         * @returns 
         */
        updateSurveyed: function (surveyed) {
            return {
                method: 'POST',
                url: config.server + 'rest/surveyed/updateSurveyed',
                data: surveyed,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
                /*,
                                headers: {
                                  'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                                }*/
            }
        },
        /**
         * url encoded
         * Delete surveyed
         * idSurveyedList=25 || idSurveyedList=23,24
         * @param {*} user 
         * @returns 
         */
        removeSurveyed: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/surveyed/removeSurveyed',
                data: data,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            }
        }
    },
    userAdmin: {
        getAllUsers: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/user/getAllUser'
            }
        },
        /**
         * url encoded
         * userCode=Tu01&email=jesus.garcia@stratesys-ts.com&language=es&role=user&using2FA=false&idCompany=undefined
         * @param {*} data 
         * @returns 
         */
        createUser: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/user/createUser',
                data: data
            }
        },
        updateUser: function (user) {
            return {
                method: 'POST',
                url: config.server + 'rest/user/updateUser',
                data: user
            }
        },
        removeUsers: function (data) {
            return {
                method: 'POST',
                url: config.server + 'rest/user/removeUsers',
                data: data,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            }
        }

    },
    items: {

    },
    common: {
        getLiterals: function (lang, tag = "front") {
            return {
                method: 'GET',
                url: config.server + 'rest/literals/getLiteralsByTag',
                params: {
                    lang: lang,
                    tag: tag
                },
                useCache: false
            }
        },
        getAllLanguages: function () {
            return {
                method: 'GET',
                url: config.server + 'rest/literals/getAllLanguages'
            }
        }
    },
    monitor: {
        getActuatorInfo: function () {
            return {
                method: 'GET',
                url: config.server + 'actuator/info',
                useCache: false
            }
        },
        getActuatorHealth: function () {
            return {
                method: 'GET',
                url: config.server + 'actuator/health',
                useCache: false
            }
        },
        getActuatorLogfile: function () {
            return {
                method: 'GET',
                url: config.server + 'actuator/logfile',
                useCache: false
            }
        },
    }
};

function parseFilters(filters) {
    return encodeURI(JSON.stringify(filters));
}

function getCurrentAPI() {
    if (config.useDemoAPI) {
        return demoAPI;
    } else {
        return realAPI;
    }
}

export const api = getCurrentAPI();
export const configuration = config;