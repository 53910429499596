import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nConfiguration from "./configuration/i18n.json"
import axios from "axios";
import { api } from "./configuration/api";
console.log(i18nConfiguration)

/**
 * key - component
 * value - events
 */
var languageChangeEvents = {

}

function addLanguageChangeEvent(component, event){

    if(event==null){
        console.error("Null event cannot be registered");
    }

    if(languageChangeEvents[component]==null){
        languageChangeEvents[component] = [event];
    }
    else{
        //Avoid event duplicity
        if(languageChangeEvents[component].indexOf(event)!=-1){
            languageChangeEvents[component].push(event);
        }
    }
}

function removeEvent(component, event){

    //Unregister fully component
    if(event==null){
        if(languageChangeEvents[component]!=null){
            delete languageChangeEvents[component];
        }
    }
    //Unregister specific event
    else{
        if(languageChangeEvents[component]!=null){
            languageChangeEvents[component] = languageChangeEvents[component].filter((registeredEvent)=> registeredEvent!==event);
        }
    }
}


var languages = []

i18nConfiguration.resources = {
    es: {
        translation: {

        }
    }
}

//SET Browser language to display default language
var userLang = navigator.language || navigator.userLanguage; 
if(userLang!=null){
    i18nConfiguration.lng = localStorage.getItem("selected-language") || userLang; //'en-US' | 'en-GB'... will be only 'en'
}

var countries = require("i18n-iso-countries");


export function getSimplifiedLocale(fullLocale){
    return fullLocale.substring(0, 2)
}

axios(api.common.getAllLanguages())
    .then((response) => {
        console.log(response)
        if (response.data.response != null) {
            languages = response.data.response;
            //languages.forEach(loadLanguage)
        }
        return languages;
    })
    .then(()=>{
        i18n.availableLanguages = languages;
        i18n.loadLanguages(languages)
        return languages
    })
    .then(()=> {
        loadLanguage(i18nConfiguration.lng)
        .then(()=>{
            i18n.changeLanguage(i18nConfiguration.lng)
            i18n.onLoad(i18nConfiguration.lng);
        })
    
    })
    .then(()=>{
        //Load other languages
        return languages.filter((language)=>{ return language != i18nConfiguration.lng})
    })
    .then(loadOtherLanguages)
    .catch(console.error)


function loadOtherLanguages(languages){
    let promises = languages.map((language)=>{

        countries.registerLocale(require("i18n-iso-countries/langs/" + getSimplifiedLocale(language) + ".json"));

        return axios(api.common.getLiterals(language))
    })

    return Promise.all(promises)
    .then((responses)=>{
        responses.forEach((response, i)=>{
            if (response.data.response != null) {
                loadLiterals(languages[i], response.data.response)
            }
        })
    })
}

function loadLanguage(language) {

    countries.registerLocale(require("i18n-iso-countries/langs/" + getSimplifiedLocale(language) + ".json"));

    return axios(api.common.getLiterals(language))
        .then((response) => {
            if (response.data.response != null) {
                /* if(i18nConfiguration.resources[language] !=null){
                     i18nConfiguration.resources[language].translation = { 
                         ...response.data.response
                     }
                 }
                 else{
                     i18nConfiguration.resources[language] = {
                         translation: {
                             ...response.data.response
                         }
                     }
                 }*/
                loadLiterals(language, response.data.response)
            }

            console.log(response)
        })
}

export function loadLiterals(language, data, namespace = "common"){
    i18n.addResourceBundle(language, namespace, data)
}

i18n
// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
//.use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
//.use(LanguageDetector)
.use(initReactI18next) // passes i18n down to react-i18next
.init(i18nConfiguration);


/**
 * Merge backend literals and frontend literals
 */
/*
axios(api.common.getLiterals())
.then((response) => {
    if(response.data.response!=null){
        response.data.response.forEach(literals => {

            let parsedLiteralsString = JSON.parse(literals.value)

            if(i18nConfiguration.resources[literals.language] !=null){
                i18nConfiguration.resources[literals.language].translation = { 
                    ...i18nConfiguration.resources[literals.language].translation, 
                    ...parsedLiteralsString
                }
            }
            else{
                i18nConfiguration.resources[literals.language] = {
                    translation: {
                        ...parsedLiteralsString
                    }
                }
            }
        });
    }


    console.log(response)
})
.catch(console.error)*/

//import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';


export default i18n;