import React, { lazy, useState } from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import i18n from './i18n';
import moment from 'moment'
import 'moment/min/locales'
import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import configuration from './configuration/configuration.json';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const PasswordRecovery = lazy(() => import('./pages/PasswordRecovery'));
const PasswordChange = lazy(() => import('./pages/PasswordChange'));
/*const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))*/

moment.locale(i18n.language);

window.onunhandledrejection = (err) => {
  console.error("it works")
}

/**
 * Display version in console
 */
console.log("Version " + configuration.version)

export default function App() {

  const [ready, setReady] = useState(false);

  /**
   * Enable CORS and JSESSIONID
   * TODO - Remove
   */
  axios.defaults.crossDomain = configuration.axios.crossDomain;
  axios.defaults.withCredentials = configuration.axios.withCredentials;
  axios.defaults.adapter = cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' });

  i18n.onLoad = function(lng){
    setReady(true)
  }


  return (
    <Router>
      <AccessibleNavigationAnnouncer />
      {ready ?
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/password-recovery" component={PasswordRecovery} />
        <Route path="/password-change" component={PasswordChange} />
        {/* Place new routes over this */}
        <Route path="/app" component={Layout} />
        {/* If you have an index page, you can remothis Redirect */}
        <Redirect exact from="/" to="/login" />
      </Switch>
      : <Switch></Switch>}
    </Router>
  )
}