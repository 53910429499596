import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/tailwind.output.css'
import 'react-notifications-component/dist/theme.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './assets/css/custom.css'
import './assets/css/react-toggle.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import * as serviceWorker from './serviceWorker'
import { ReactNotifications } from 'react-notifications-component'

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
  <SidebarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <ReactNotifications />
      <App />
    </Suspense>
  </SidebarProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
