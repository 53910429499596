import { t } from 'i18next';
import React from 'react'
import CircleLoader from './Loading/CircleLoader';

function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
      {/*t("generic.loading")*/ }
      <CircleLoader></CircleLoader>
    </div>
  )
}
export default ThemedSuspense;