import { t } from 'i18next'
import React from 'react'

export default function CircleLoader({ className = '', width = "48px", height = "48px" }) {
    return (
        <div className={'flex items-center  ' + className} >
            <span class='loader' style={{ width: width, height: height }}></span>
            <div className='ml-2'>
                {t("generic.loading")}
            </div>
        </div>

    )
}